<template>
    <div>
        <el-form :model="form" :rules="formRules" ref="form" label-position="top" status-icon>
            <el-row :gutter="50">
                <el-col :xs="24" :md="12">
                    <div class="box-title">{{ $t('register.title.account') }}</div>

                    <el-form-item :label="$t('register.label.name')" prop="name">
                        <el-input v-model="form.name" :placeholder="$t('register.placeholder.name')"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('register.label.username')" prop="username">
                        <el-input v-model="form.username" :placeholder="$t('register.placeholder.username')"></el-input>
                    </el-form-item>

                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12">
                            <el-form-item :label="$t('register.label.password')" prop="password">
                                <el-input
                                    type="password"
                                    v-model="form.password"
                                    :placeholder="$t('register.label.password')"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12">
                            <el-form-item :label="$t('register.label.confirm-password')" prop="confirm_password">
                                <el-input
                                    type="password"
                                    v-model="form.confirm_password"
                                    :placeholder="$t('register.label.confirm-password')"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div class="box-title">{{ $t('register.title.company') }}</div>

                    <el-form-item :label="$t('register.label.company-name')" prop="company_name">
                        <el-input v-model="form.company_name"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('account.profile.addresses.label-country')" prop="country_id">
                        <el-select @change="countryChange" v-model="form.country_id" class="fullWidth" filterable>
                            <el-option
                                v-for="country in countriesList"
                                :key="country.id"
                                :label="country.name"
                                :value="country.id"
                            >
                                <i :class="country.iso + ' flag'"></i>
                                <span>{{ country.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <template v-if="form.country_id == 1">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                                <el-form-item :label="$t('register.label.county')" prop="county">
                                    <el-select
                                        @change="countyChange"
                                        class="fullWidth"
                                        v-model="form.county"
                                        filterable
                                    >
                                        <el-option
                                            v-for="county in countiesList"
                                            :key="county"
                                            :label="county"
                                            :value="county"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12">
                                <el-form-item :label="$t('register.label.city')" prop="city">
                                    <el-select
                                        class="fullWidth"
                                        v-model="form.city"
                                        :disabled="!this.form.county"
                                        filterable
                                    >
                                        <el-option
                                            v-for="city in citiesList"
                                            :key="city"
                                            :label="city"
                                            :value="city"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>

                    <template v-else>
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12">
                                <el-form-item :label="$t('register.label.county')" prop="county">
                                    <el-input v-model="form.county"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12">
                                <el-form-item :label="$t('register.label.city')" prop="city">
                                    <el-input v-model="form.city"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>

                    <el-form-item :label="$t('register.label.company-address')" prop="company_address">
                        <el-input v-model="form.company_address"></el-input>
                    </el-form-item>

                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="form.country_id == 1 ? 12 : 24">
                            <el-form-item :label="$t('register.label.cif')" prop="cif">
                                <el-input v-model="form.cif"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col v-if="form.country_id == 1" :xs="24" :sm="12">
                            <el-form-item :label="$t('register.label.regcom')" prop="regcom">
                                <el-input v-model="form.regcom"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item :label="$t('register.label.tva')" prop="tva">
                        <div>
                            <el-radio v-model="form.tva" label="1" border>Da</el-radio>
                            <el-radio v-model="form.tva" label="0" border>Nu</el-radio>
                        </div>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :md="12">
                    <div class="box-title">{{ $t('register.title.bank') }}</div>

                    <el-form-item :label="$t('register.label.bank')" prop="bank">
                        <el-input v-model="form.bank"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('register.label.iban')" prop="iban">
                        <el-input v-model="form.iban"></el-input>
                    </el-form-item>

                    <!-- <div class="box-title">{{ $t('register.title.address') }}</div> -->

                    <div class="box-title">{{ $t('register.title.contact') }}</div>

                    <el-form-item :label="$t('register.label.phone')" prop="phone">
                        <vue-tel-input
                            v-model="phone"
                            :inputOptions="{ showDialCode: true, tabindex: 0 }"
                            defaultCountry="ro"
                            mode="international"
                            @validate="onPhoneValidate"
                        >
                        </vue-tel-input>
                    </el-form-item>

                    <el-form-item :label="$t('register.label.email')" prop="email">
                        <el-input v-model="form.email"></el-input>
                    </el-form-item>

                    <!-- <hr class="separator" /> -->

                    <div class="box-title">{{ $t('register.label.source') }}</div>

                    <el-form-item prop="source">
                        <el-input type="textarea" :rows="3" v-model="form.source"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-dialog title="Verficare" :visible.sync="form.captchaDialog.visible" width="30%" :close="resetCaptchaDialog">
            <div class="sub-title">Introdu textul din imagine pentru a continua</div>
            <div class="center" v-loading="loading">
                <img class="captcha-image" :src="form.captchaDialog.image" alt="" height="50" />
                <el-input class="captcha-input" v-model="form.captchaDialog.input"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="captchaDialog = false">Cancel</el-button> -->
                <el-button class="b-c-sc" type="primary" @click="sendCaptcha" :loading="loading">Confirm</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Message } from 'element-ui';
import { checkCIF, checkIBAN } from '@/utils/index';

import { LocalError } from '@/utils/errorHandler';

import { VueTelInput } from 'vue-tel-input';

const phoneStatus = {
    number: '',
    status: false,
};

export default {
    components: {
        VueTelInput,
    },
    data() {
        const validateName = (rule, value, callback) => {
            if (/^([a-zA-Z0-9_-\s]){4,32}$/.test(value.trim()) != true) {
                return callback(new Error(this.$t('register.error.name')));
            }

            this.$store
                .dispatch('leads/CheckName', {
                    name: value,
                })
                .then((res) => {
                    if (res.available == true) {
                        return callback();
                    } else {
                        return callback(new Error(this.$t('register.error.name-exists')));
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'checkName',
                            params: {
                                name: value,
                            },
                        },
                    });
                });
        };
        const validateUsername = (rule, value, callback) => {
            if (/^(?=^[^_]+_?[^_]+$)\w{6,32}$/.test(value) != true) {
                return callback(new Error(this.$t('register.error.username')));
            }

            this.$store
                .dispatch('leads/CheckUsername', {
                    username: value,
                })
                .then((res) => {
                    if (res.available == true) {
                        return callback();
                    } else {
                        return callback(new Error(this.$t('register.error.username-exists')));
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'checkUsername',
                            params: {
                                username: value,
                            },
                        },
                    });
                });
        };
        const validateConfirmPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('register.error.confirm-password')));
            } else if (value !== this.form.password) {
                callback(new Error(this.$t('register.error.confirm-password')));
            } else {
                callback();
            }
        };
        const validateCif = (rule, value, callback) => {
            if ((this.form.country_id !== 1 && value.length > 3) || checkCIF(value)) {
                callback();
            } else {
                callback(new Error(this.$t('register.error.cif')));
            }
        };
        const validateIBAN = (rule, value, callback) => {
            if (checkIBAN(value)) {
                this.$store
                    .dispatch('leads/CheckIBAN', {
                        iban: value,
                    })
                    .then((res) => {
                        if (res.available == true) {
                            return callback();
                        } else {
                            return callback(new Error(this.$t('register.error.iban-exists')));
                        }
                    })
                    .catch((e) => {
                        this.$reportToSentry(e, {
                            extra: {
                                fn: 'checkIBAN',
                                params: {
                                    iban: value,
                                },
                            },
                        });
                    });
            } else {
                callback(new Error(this.$t('register.error.iban')));
            }
        };

        const validateRegcom = (rule, value, callback) => {
            // Expresia regulată pentru formatul vechi
            const oldFormatRegex = /^[a-z0-9]+\/[a-z0-9]+\/[0-9]{4}$/i;

            // Expresia regulată pentru formatul nou
            const newFormatRegex = /^[JFC]\d{4}\d{6}\d{2}\d$/;

            // Validăm împotriva celor două formate
            if (!oldFormatRegex.test(value) && !newFormatRegex.test(value)) {
                console.error('Formatul nu este valid');
                return callback(new Error(this.$t('register.error.regcom')));
            }

            // Totul este valid
            callback();
        };
        const validatePhone = (rule, value, callback) => {
            if (!phoneStatus.status) {
                return callback(Error(this.$t('register.error.phone')));
            }
            return callback();
        };
        return {
            loading: false,
            phone: '',
            form: {
                name: '',
                username: '',
                password: '',
                confirm_password: '',
                company_name: '',
                cif: '',
                regcom: '',
                tva: '',
                bank: '',
                iban: '',
                country_id: 1,
                county: '',
                city: '',
                company_address: '',
                phone: '',
                email: '',
                source: '',
                captchaDialog: {
                    visible: false,
                    url: {},
                    session: {},
                    image: '',
                    input: '',
                },
            },
            formRules: {
                name: [
                    {
                        required: true,
                        validator: validateName,
                        trigger: 'blur',
                    },
                ],
                username: [
                    {
                        required: true,
                        validator: validateUsername,
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: this.$t('register.error.password'),
                        trigger: 'blur',
                    },
                    {
                        min: 6,
                        max: 32,
                        message: this.$t('register.error.password-length'),
                        trigger: 'blur',
                    },
                ],
                confirm_password: [
                    {
                        required: true,
                        validator: validateConfirmPassword,
                        trigger: 'blur',
                    },
                ],
                company_name: [
                    {
                        required: true,
                        message: this.$t('register.error.company-name'),
                        trigger: 'blur',
                    },
                ],
                cif: [
                    {
                        required: true,
                        validator: validateCif,
                        trigger: 'blur',
                    },
                ],
                regcom: [
                    {
                        required: true,
                        validator: validateRegcom,
                        trigger: 'blur',
                    },
                ],
                tva: [
                    {
                        required: true,
                        message: this.$t('register.error.tva'),
                        trigger: 'change',
                    },
                ],
                bank: [
                    {
                        required: true,
                        message: this.$t('register.error.bank'),
                        trigger: 'blur',
                    },
                ],
                iban: [
                    {
                        required: true,
                        validator: validateIBAN,
                        trigger: 'blur',
                    },
                ],
                country_id: [
                    {
                        required: true,
                        message: this.$t('register.error.country'),
                        trigger: 'change',
                    },
                ],
                county: [
                    {
                        required: true,
                        message: this.$t('register.error.county'),
                        trigger: 'change',
                    },
                ],
                city: [
                    {
                        required: true,
                        message: this.$t('register.error.city'),
                        trigger: 'change',
                    },
                ],
                company_address: [
                    {
                        required: true,
                        message: this.$t('register.error.company-address'),
                        trigger: 'blur',
                        min: 6,
                        max: 100,
                    },
                ],
                phone: [
                    {
                        required: true,
                        validator: validatePhone,
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: this.$t('register.error.email'),
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        async nextStep() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$store
                            .dispatch('leads/AddAccount', this.form)
                            .then((res) => {
                                this.loading = false;

                                if (res.message.status === 0) {
                                    if (res.message.clearCaptcha != null) {
                                        this.resetCaptchaDialog();
                                    }
                                    this.sbMsg({
                                        type: 'warn',
                                        message: res.message.message,
                                    });
                                    return reject(new Error(res.message.message));
                                } else {
                                    if (res.message.status === 2) {
                                        this.form.captchaDialog.image = res.message.data.image;
                                        this.form.captchaDialog.url = res.message.data.url;
                                        this.form.captchaDialog.session = res.message.data.browserSession;
                                        this.form.captchaDialog.visible = true;
                                        reject(new Error(2));
                                    } else {
                                        this.form.captchaDialog.visible = false;
                                        this.$store
                                            .dispatch('user/Login', {
                                                username: this.form.username,
                                                password: this.form.password,
                                            })
                                            .then((res) => resolve());
                                    }
                                }
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    } else {
                        this.sbMsg({
                            type: 'error',
                            message: this.$t('register.error.fields'),
                        });
                        reject(new LocalError(this.$t('register.error.fields')));
                    }
                });
            });
        },
        resetCaptchaDialog() {
            this.form.captchaDialog = {
                visible: false,
                url: {},
                session: {},
                image: '',
                input: '',
            };
        },
        sendCaptcha() {
            if (this.form.captchaDialog.input.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Nu ai introdus textul din imagine!',
                });
                return false;
            }
            this.$emit('nextStep');
        },
        countryChange() {
            this.form.county = '';
            this.form.city = '';
        },
        countyChange() {
            this.form.city = '';

            this.$store
                .dispatch('leads/GetRegisterCities', {
                    county: this.form.county,
                })
                .then()
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getRegisterCities',
                        },
                    });
                });
        },
        onPhoneValidate(data) {
            this.form.phone = data.number;
            phoneStatus.status = data.valid;
        },
    },
    computed: {
        ...mapState({
            activeStep: (state) => state.leads.activeStep,
            registerRequest: (state) => state.leads.registerRequest,
            cities: (state) => state.leads.cities,
            captchaDialog: (state) => state.leads.captchaDialog,
        }),
        countriesList() {
            return this.registerRequest.countries || [];
        },
        countiesList() {
            return this.registerRequest.counties || [];
        },
        citiesList() {
            return this.cities || [];
        },
    },
    watch: {
        registerRequest() {
            this.phone = this.registerRequest.phone || '';
            this.$set(this.form, 'phone', this.registerRequest.phone || '');
            this.$set(this.form, 'email', this.registerRequest.email || '');
            this.$forceUpdate();
        },
    },
    created() {},
    mounted() {
        this.phone = this.registerRequest.phone || '';
        this.$set(this.form, 'phone', this.registerRequest.phone || '');
        this.$set(this.form, 'email', this.registerRequest.email || '');
        this.$forceUpdate();
    },
    destroyed() {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.box-title {
    font-size: 24px;
    color: #f17146;
    margin-bottom: 10px;
    margin-top: 10px;
}

.separator {
    background: #cccccc;
    border: none;
    height: 1px;
}
</style>

